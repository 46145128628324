import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { Shipping } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getErrors } from "../../utils/errors";
import { getOrderSummaryRequest } from "../Order";
import { showErrorModal } from "../UI";
import { goToOrderCheckout } from "../../constants/routes";

export interface ShippingState {
  data: Shipping | null;
  error: null | any;
  loading: boolean;
  loaded: boolean;
}

const initialState: ShippingState = {
  data: null,
  error: null,
  loading: false,
  loaded: false,
};

// DEFINE SAGAS
function* updateShippingSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    const { orderId, designId, productionId, shippingId, payload, navigate } =
      action.payload;
    const headers = getAuthHeader();
    const response: any = yield call(
      fetch,
      `${apiUrl}/orders/orders/${orderId}/shipping/${shippingId}/`,
      {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      }
    );
    const data = yield response.json();

    if (response.ok) {
      yield put(updateShippingSuccess(data));
      yield put(getOrderSummaryRequest(orderId));
      const route = goToOrderCheckout(designId, orderId, productionId);
      navigate(route);
    } else {
      yield put(updateShippingFailure(data));

      yield put(showErrorModal(getErrors(data)));
    }
  } catch (e) {
    // console.error("SHIPPING/UPDATE:", e);
  }
}

function* setShippingSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  try {
    // const { orderId, payload } = action.payload;
    const { orderId, designId, productionId, shippingId, payload, navigate } =
      action.payload;

    const headers = getAuthHeader();

    const response: any = yield call(
      fetch,
      `${apiUrl}/orders/orders/${orderId}/shipping/`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      }
    );
    const data = yield response.json();

    if (response.ok) {
      yield put(setShippingSuccess(data));
      yield put(getOrderSummaryRequest(orderId));
      const route = goToOrderCheckout(designId, orderId, productionId);
      navigate(route);
    } else {
      yield put(setShippingFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (e) {
    // console.error("SHIPPING/SET:", e);
  }
}

export const getShippingFromOrderThunk = createAsyncThunk(
  "shipping/this_order",
  async (orderId: string) => {
    const headers = getAuthHeader();
    const response = await fetch(
      `${apiUrl}/orders/orders/${orderId}/shipping/this_order/`,
      {
        headers: headers,
        credentials: "include",
      }
    );
    return await response.json();
  }
);

export const shippingRangeSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    resetShipping: (state) => {
      state.data = initialState.data;
      state.error = initialState.error;
      state.loaded = true;
    },
    setShippingRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
      state.loaded = false;
    },
    setShippingSuccess: (state, action) => {
      state.data = action.payload;
      state.error = initialState.error;
      state.loading = false;
      state.loaded = true;
    },
    setShippingFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    updateShippingRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
      state.loaded = false;
    },
    updateShippingSuccess: (state, action) => {
      state.data = action.payload;
      state.error = initialState.error;
      state.loading = false;
      state.loaded = true;
    },
    updateShippingFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShippingFromOrderThunk.pending, (state) => {
      state.data = null;
    });
    builder.addCase(getShippingFromOrderThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getShippingFromOrderThunk.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

// TODO define actions better in bindActionCreators.tsx
export const {
  resetShipping,
  setShippingRequest,
  setShippingSuccess,
  setShippingFailure,
  updateShippingRequest,
  updateShippingSuccess,
  updateShippingFailure,
} = shippingRangeSlice.actions;

export default shippingRangeSlice.reducer;

export function* sagas() {
  yield takeEvery(updateShippingRequest.type, updateShippingSaga);
  yield takeEvery(setShippingRequest.type, setShippingSaga);
}
