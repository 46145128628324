import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.header<{ $hidden?: boolean }>`
  display: ${(props) => (props.$hidden ? "none" : "block")};

  position: sticky;
  z-index: 999;
  top: 0;
  background: var(--white);
  border-bottom: 1px solid var(--purple30);
  .header-container {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 52px;
    align-items: center;
    gap: 40px;
    flex-shrink: 0;

    .logo {
      color: var(--purple100);
    }

    .logo {
      width: 76px;
    }

    .content {
      width: 100%;
      ul {
        display: flex;
        gap: 40px;
        list-style: none;
        li {
          cursor: pointer;
          a {
            font-family: "Helvetica Bold";
            font-size: 16px;
            font-style: normal;
            line-height: 19.2px;
            text-decoration: none;
            padding-bottom: 18px;
            color: var(--gray90);
            &:hover {
              color: var(--gray110);
              border-bottom: 1px solid var(--gray110);
            }
            &.active {
              color: var(--purple100);
              border-bottom: 1px solid var(--purple100);
            }
          }
        }
      }
    }

    .product-details {
      display: flex;
      justify-content: center;
      .product-name {
        color: var(--purple100);
        text-align: center;
      }
      .product-details-divider {
        margin: 0 16px;
        background: var(--purple30);
        width: 1px;
        height: 20px;
      }
      .product-type {
        color: var(--gray90);
        text-align: center;
      }
    }
    .action {
      position: relative;
      justify-content: end;
      display: flex;
      align-items: center;
      gap: 8px;

      text-wrap: nowrap;
      height: 50px;
      .lang {
        text-transform: capitalize;
      }
      .logged-actions,
      .lang-actions {
        position: absolute;
        top: calc(var(--headerHeight) - 13px);
        right: 0;
        background: var(--white);
        width: 240px;
        padding: 8px;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        display: none;
        flex-direction: column;
        align-items: start;
        justify-content: center;

        .action-item-text,
        .action-item {
          width: 100%;
          height: 40px;
        }
        .action-item-text {
          padding: 0 8px;
        }
        .action-item {
          padding: 0 5px;
          display: flex;
          &:hover {
            background: var(--gray20);
          }
        }
      }
    }
    #logged:hover {
      .logged-actions {
        display: flex;
      }
    }
    #lang:hover {
      .lang-actions {
        display: flex;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    .header-container {
      justify-content: space-around;
      gap: 20px;
      .content {
        ul {
          gap: 20px;
          padding: 0;
        }
        .product-details {
          flex-direction: column;
          .product-details-divider {
            display: none;
          }
          .product-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 220px;
          }
          .product-type {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 220px;
          }
        }
      }
      .action {
        width: 74px;
        > button {
          text-indent: -99999999px;
        }
        .logged-actions {
          width: 200px;
        }
      }
    }
  }
`;
