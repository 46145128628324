import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../store";
import { isLoggedRequest } from "../store/Login";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const login = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch<AppDispatch>();
  let location = useLocation();

  useEffect(() => {
    dispatch(isLoggedRequest());
  }, []);

  if (login.logged === null) return;
  if (!login.logged && !login.loading) {
    return <Navigate to="/access" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
